import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./StoreProduct.css";
import { useNavigate } from "react-router-dom";
import { useStoreProduct } from "../context/StoreProductsContext";

type StorProductPropsType = {
  id: number;
  title: string;
  by: string;
  description: string;
  colors: { color: string; productImg: string; sizes: string[] }[];
  price: string;
};

const StoreProducts = ({
  by,
  colors,
  description,
  id,
  price,
  title,
}: StorProductPropsType) => {
  // const [addToCart, SetAddToCart] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [chosenProduct, setChosenProduct] = useState<{
    color?: string;
    productImg?: string;
    sizes?: string;
  }>({});
  const [sizes, setSizes] = useState<string[] | null>(null);
  const navigate = useNavigate();
  const { addProductsToCart, decreaseCartQuantity, getItemQuantity } =
    useStoreProduct();
  const quantity: number = getItemQuantity(id);

  useEffect(() => {
    setImageUrl(colors[0].productImg);
    colors.forEach((c) => {
      setSizes(c.sizes);
    });
  }, []);

  return (
    <div className="col-md-4 col-6">
      <Card
        style={{
          border: "none",
          backgroundColor: "#EFEAD1",
        }}
        className="productMainDiv col-4"
      >
        <Card.Img
          className="productImage"
          variant="top"
          src={imageUrl}
          onClick={() => navigate("/productDetails?id=" + id)}
        />
        <Card.Body>
          <Card.Text>
            <h1 className="productHeader">{title}</h1>
            <p className="productSubHeader">{by}</p>

            <div className="d-flex justify-content-around">
              <div className="w-50">
                <p className="ColorAndSize">Color</p>
                <div className="d-flex flex-wrap">
                  {colors.map((c) => {
                    return (
                      <div
                        className="d-flex mb-1 justify-content-center align-items-center mainColorDiv colorDiv "
                        style={{
                          border:
                            chosenProduct?.color === c.color
                              ? "1.5px solid #1C1C1C"
                              : "",
                        }}
                      >
                        <div
                          className="colorDiv d-inline-block"
                          style={{ backgroundColor: c.color }}
                          onClick={() => {
                            setImageUrl(c.productImg);
                            setChosenProduct((pre) => {
                              return {
                                ...pre,
                                productImg: c.productImg,
                                color: c.color,
                              };
                            });
                          }}
                        ></div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-50">
                <p className="ColorAndSize">Size</p>
                <div className="d-flex flex-wrap">
                  {sizes?.map((s) => {
                    return (
                      <div
                        className="SizeDiv mb-1"
                        style={{
                          backgroundColor:
                            chosenProduct?.sizes === s ? "#1C1C1C" : "",
                          color: chosenProduct?.sizes === s ? "#F3F3F3" : "",
                        }}
                      >
                        <span
                          onClick={() => {
                            setChosenProduct((pre) => {
                              return { ...pre, sizes: s };
                            });
                          }}
                        >
                          {s}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h1 className="priceDiv">Kr {price}</h1>
                <p className="TaxDiv">Tax Included</p>
              </div>
              {quantity === 0 && (
                <button
                  className="productAddToCart"
                  onClick={() => {
                    if (
                      chosenProduct.color == null ||
                      chosenProduct.sizes == null
                    )
                      return;
                    addProductsToCart({
                      id: id,
                      color: chosenProduct.color,
                      price: price,
                      productId: id,
                      quantity: 0,
                      size: chosenProduct.sizes,
                    });
                  }}
                >
                  Add To Cart
                </button>
              )}
              {quantity > 0 && (
                <div className="productCounts d-flex justify-content-between align-items-center">
                  <button
                    className="productCountsButton"
                    onClick={() => {
                      if (
                        chosenProduct.color == null ||
                        chosenProduct.sizes == null
                      )
                        return;
                      addProductsToCart({
                        id: id,
                        color: chosenProduct.color,
                        price: price,
                        productId: id,
                        quantity: 0,
                        size: chosenProduct.sizes,
                      });
                    }}
                  >
                    +
                  </button>
                  <p className="m-0">{quantity}</p>
                  <button
                    className="productCountsButton"
                    onClick={() => {
                      if (
                        chosenProduct.color == null ||
                        chosenProduct.sizes == null
                      )
                        return;
                      decreaseCartQuantity({
                        id: id,
                        color: chosenProduct.color,
                        price: price,
                        productId: id,
                        quantity: 0,
                        size: chosenProduct.sizes,
                      });
                    }}
                  >
                    -
                  </button>
                </div>
              )}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default StoreProducts;
