import React, { createContext, useContext, useState } from "react";

type ChosenMenuContextType = {
  ChangeMenu: (menu: number) => void;
  chosenMenu: number;
};

type ChosenMenuProviderProps = {
  children: React.ReactNode;
};

const chosenMenuContext = createContext({} as ChosenMenuContextType);

export function useChosenMenu() {
  return useContext(chosenMenuContext);
}

export const ChosenMenuContextProvider = ({
  children,
}: ChosenMenuProviderProps) => {
  const [chosenMenu, setChosenMenu] = useState(0);

  const ChangeMenu = (menu: number = 0) => {
    setChosenMenu(menu);
  };

  return (
    <chosenMenuContext.Provider value={{ ChangeMenu, chosenMenu }}>
      {children}
    </chosenMenuContext.Provider>
  );
};
