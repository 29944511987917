import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ChangeBackGroundColorContextProvider } from "./context/ChangeBackGroundColorContext";
import { ChangeLanguageContextProvider } from "./context/ChangeLanguageContext";
import { StoreProductsContextProvider } from "./context/StoreProductsContext";
import { AuthProvider } from "./context/AuthContext";
import { ChosenMenuContextProvider } from "./context/ChangeMenuContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthProvider>
        <ChangeBackGroundColorContextProvider>
          <ChangeLanguageContextProvider>
            <StoreProductsContextProvider>
              <ChosenMenuContextProvider>
                <App />
              </ChosenMenuContextProvider>
            </StoreProductsContextProvider>
          </ChangeLanguageContextProvider>
        </ChangeBackGroundColorContextProvider>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
