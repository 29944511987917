import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import axios from "axios";
import "./AddArticle.css";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";

interface Paragraph {
  paragraphTitle: string;
  paragraphDetails: string;
}

interface ArticleDataType {
  id: number;
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
  description: string;
  takingBy: string;
  paragraphs: Paragraph[];
}

type AddArticlePropsType = {
  edit: boolean;
  articleId: string | null;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>> | null;
};

const AddArticle: React.FC<AddArticlePropsType> = ({
  edit,
  articleId,
  setShowEdit,
}) => {
  const [sharedImages, setSharedImages] = useState<File[]>([]);
  const [backgroundImage, setBackgroundImage] = useState<File | null>(null);
  const [showIcon, setShowIcon] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formData, setFormData] = useState<{
    en: ArticleDataType;
    ar: ArticleDataType;
    imagesUrl: string[];
    backgroundImg: string | null;
  }>({
    en: {
      id: 0,
      title: "",
      subTitle: "",
      date: "",
      type: "",
      place: "",
      description: "",
      takingBy: "",
      paragraphs: [{ paragraphTitle: "", paragraphDetails: "" }],
    },
    ar: {
      id: 0,
      title: "",
      subTitle: "",
      date: "",
      type: "",
      place: "",
      description: "",
      takingBy: "",
      paragraphs: [{ paragraphTitle: "", paragraphDetails: "" }],
    },
    imagesUrl: [],
    backgroundImg: null,
  });

  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/articles/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        setFormData({ ...datab });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (edit && articleId !== null) {
      getData(articleId);
      console.log(formData);
    }
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleChange = (
    lang: "en" | "ar",
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [lang]: {
        ...formData[lang],
        [name]: value,
      },
    });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setSharedImages([...sharedImages, ...Array.from(files)]);
    }
  };

  const handleBackgroundImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setBackgroundImage(file);
    }
  };

  const handleRemoveImage = (index: number) => {
    const newImages = [...sharedImages];
    newImages.splice(index, 1);
    setSharedImages(newImages);
  };

  const handleRemoveImageFrmDatabase = async (
    index: number,
    imageUrl: string
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmed) {
      // If the user cancels, do nothing
      return;
    }
    const newImages = [...formData.imagesUrl];
    newImages.splice(index, 1);
    console.log(newImages);
    setFormData({ ...formData, imagesUrl: newImages });
    formData.imagesUrl = newImages;
    const formDataToSend = new FormData();
    const token = localStorage.getItem("authToken");

    formDataToSend.append("data", JSON.stringify(formData));
    formDataToSend.append("imageUrl", JSON.stringify(imageUrl));

    const response = await axios.post(
      `${baseUrl}/api/articleDeleteImage/${articleId}`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      }
    );
    console.log("Response:", response.data);
  };

  const handleRemoveBackGroundImageFrmDatabase = async (
    imageUrl: string | null
  ) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (!confirmed) {
      // If the user cancels, do nothing
      return;
    }
    setFormData({ ...formData, backgroundImg: null });
    formData.backgroundImg = null;
    const formDataToSend = new FormData();
    const token = localStorage.getItem("authToken");

    formDataToSend.append("data", JSON.stringify(formData));
    formDataToSend.append("imageUrl", JSON.stringify(imageUrl));

    const response = await axios.post(
      `${baseUrl}/api/articleDeleteImage/${articleId}`,
      formDataToSend,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-access-token": token,
        },
      }
    );
    console.log("Response:", response.data);
  };

  const handleParagraphChange = (
    lang: "en" | "ar",
    index: number,
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    const newParagraphs = [...formData[lang].paragraphs];
    newParagraphs[index][name as keyof Paragraph] = value;
    setFormData({
      ...formData,
      [lang]: {
        ...formData[lang],
        paragraphs: newParagraphs,
      },
    });
  };

  const addParagraph = (lang: "en" | "ar") => {
    setFormData({
      ...formData,
      [lang]: {
        ...formData[lang],
        paragraphs: [
          ...formData[lang].paragraphs,
          { paragraphTitle: "", paragraphDetails: "" },
        ],
      },
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    const token = localStorage.getItem("authToken");
    setError(false);
    setErrorMessage("");
    formDataToSend.append("data", JSON.stringify(formData));

    sharedImages.forEach((file, index) => {
      formDataToSend.append(`images`, file);
    });

    if (!backgroundImage && !formData.backgroundImg) {
      setError(true);
      setErrorMessage("Don't forget to add a background Image.");
      return;
    }

    if (backgroundImage) {
      formDataToSend.append("backgroundImage", backgroundImage);
    }

    try {
      if (edit) {
        const response = await axios.post(
          `${baseUrl}/api/article/${articleId}`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-access-token": token,
            },
          }
        );
        if (response.status === 200) {
          setShowIcon(true);
        }
      } else {
        const response = await axios.post(
          `${baseUrl}/api/article`,
          formDataToSend,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "x-access-token": token,
            },
          }
        );
        if (response.status === 200) {
          setShowIcon(true);
          setFormData({
            en: {
              id: 0,
              title: "",
              subTitle: "",
              date: "",
              type: "",
              place: "",
              description: "",
              takingBy: "",
              paragraphs: [{ paragraphTitle: "", paragraphDetails: "" }],
            },
            ar: {
              id: 0,
              title: "",
              subTitle: "",
              date: "",
              type: "",
              place: "",
              description: "",
              takingBy: "",
              paragraphs: [{ paragraphTitle: "", paragraphDetails: "" }],
            },
            imagesUrl: [],
            backgroundImg: null,
          });
          setBackgroundImage(null);
          setSharedImages([]);
        }
      }
    } catch (error: any) {
      setError(true);
      setErrorMessage(error.message);
      console.log(error);
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}
      <form onSubmit={handleSubmit}>
        {edit && (
          <button onClick={() => setShowEdit && setShowEdit(false)}>
            Back to edit
          </button>
        )}
        <h2>Add Article Details</h2>

        <section>
          <label>Images:</label>
          <input type="file" multiple onChange={handleFileChange} />
          <div className="images-preview">
            {sharedImages.map((file, index) => (
              <div key={index} className="image-preview">
                <img src={URL.createObjectURL(file)} alt={`Img ${index + 1}`} />
                <button
                  type="button"
                  className="remove-button"
                  onClick={() => handleRemoveImage(index)}
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
          <label>Background Image:</label>
          <input
            type="file"
            onChange={handleBackgroundImageChange}
            disabled={formData.backgroundImg !== null}
          />
          {backgroundImage && (
            <div className="image-preview">
              <img
                src={URL.createObjectURL(backgroundImage)}
                alt="Background"
              />
              <button
                type="button"
                className="remove-button"
                onClick={() => setBackgroundImage(null)}
              >
                &times;
              </button>
            </div>
          )}
        </section>
        {edit && (
          <section className="mt-2">
            <div className="images-preview">
              {formData.imagesUrl.map((url, index) => (
                <div key={index} className="image-preview">
                  <img src={url} alt={`Img ${index + 1}`} />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() => handleRemoveImageFrmDatabase(index, url)}
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
            <div className="mt-3">
              <p>Background Image:</p>
              {formData.backgroundImg !== null && (
                <div className="image-preview">
                  <img src={formData.backgroundImg} alt="Background" />
                  <button
                    type="button"
                    className="remove-button"
                    onClick={() =>
                      handleRemoveBackGroundImageFrmDatabase(
                        formData.backgroundImg
                      )
                    }
                  >
                    &times;
                  </button>
                </div>
              )}
            </div>
          </section>
        )}
        <Title title="Add Article in English" />
        {renderArticleForm("en")}

        <Title title="Add Article in Arabic" rtl />
        {renderArticleForm("ar")}

        <button type="submit">Submit</button>
        {error && <p className="bg-warning px-3 mt-1">{errorMessage}</p>}
      </form>
    </>
  );

  function Title({ title, rtl }: { title: string; rtl?: boolean }) {
    return <h2 className={rtl ? "rtl" : ""}>{title}</h2>;
  }

  function renderArticleForm(lang: "en" | "ar") {
    return (
      <div className={lang === "ar" ? "rtl" : ""}>
        <label>
          {lang === "ar" ? "العنوان" : "Title"}:
          <input
            type="text"
            name="title"
            value={formData[lang].title}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "العنوان الفرعي" : "Subtitle"}:
          <input
            type="text"
            name="subTitle"
            value={formData[lang].subTitle}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "التاريخ" : "Date"}:
          <input
            type="date"
            name="date"
            value={formData[lang].date}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "النوع" : "Type"}:
          <input
            type="text"
            name="type"
            value={formData[lang].type}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "المكان" : "Place"}:
          <input
            type="text"
            name="place"
            value={formData[lang].place}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "الوصف" : "Description"}:
          <textarea
            name="description"
            value={formData[lang].description}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>

        <label>
          {lang === "ar" ? "المصور" : "Taken By"}:
          <input
            type="text"
            name="takingBy"
            value={formData[lang].takingBy}
            onChange={(e) => handleChange(lang, e)}
          />
        </label>

        <h3>{lang === "ar" ? "الفقرات" : "Paragraphs"}</h3>
        {formData[lang].paragraphs.map((paragraph, index) => (
          <div key={index}>
            <label>
              {lang === "ar" ? "عنوان الفقرة" : "Paragraph Title"}:
              <input
                type="text"
                name="paragraphTitle"
                value={paragraph.paragraphTitle}
                onChange={(e) => handleParagraphChange(lang, index, e)}
              />
            </label>

            <label>
              {lang === "ar" ? "تفاصيل الفقرة" : "Paragraph Details"}:
              <textarea
                name="paragraphDetails"
                value={paragraph.paragraphDetails}
                onChange={(e) => handleParagraphChange(lang, index, e)}
              />
            </label>
          </div>
        ))}
        <button type="button" onClick={() => addParagraph(lang)}>
          {lang === "ar" ? "أضف فقرة أخرى" : "Add Another Paragraph"}
        </button>
      </div>
    );
  }
};

export default AddArticle;
