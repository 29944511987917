import React, { FormEvent, useState } from "react";
import "./Login.css";
import axios from "axios";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../DataUrl";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowError(false);
    setError("");

    if (username.length < 6) {
      setShowError(true);
      setError("The username must be 8 characters or longer");
      return;
    }

    if (password.length < 7) {
      setShowError(true);
      setError("The password must be 8 characters or longer");
      return;
    }

    try {
      const response = await axios.post(`${baseUrl}/api/login`, {
        username,
        password,
      });
      if (response.status === 200) {
        const { token, admin } = response.data;
        login(token, admin);
        navigate("/dashboard");
      }
    } catch (er) {
      setShowError(true);
      setError("Incorrect username or Password");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="userForm">
      <h2>Login</h2>
      <label>
        Username:
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
      </label>
      <label>
        Password:
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </label>
      {showError && <p className="text-danger">{error}</p>}
      <button type="submit">Login</button>
    </form>
  );
}

export default Login;
