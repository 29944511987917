import React, { FormEvent, useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";

type AddSignUpPropsType = {
  edit: boolean;
  userId: string | null;
  setShowEdit: React.Dispatch<React.SetStateAction<boolean>> | null;
};

const SignUp: React.FC<AddSignUpPropsType> = ({
  edit,
  userId,
  setShowEdit,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [repeatedPassword, setRepeatedPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [admin, setAdmin] = useState(false);
  const [showIcon, setShowIcon] = useState(false);

  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/users/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        console.log(datab);
        setUsername(datab.id);
        setAdmin(datab.admin);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (edit && userId !== null) {
      getData(userId);
      console.log(userId);
    }
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleSignup = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowError(false);
    setError("");
    const token = localStorage.getItem("authToken");

    if (username.length < 6) {
      setError("The username must be 6 characters or longer");
      setShowError(true);
      return;
    }
    if (password.length < 7) {
      setError("The password must be 6 characters or longer");
      setShowError(true);
      return;
    }

    if (password !== repeatedPassword) {
      setError("The passwords doesn't match.");
      setShowError(true);
      return;
    }

    try {
      if (edit) {
        const response = await axios.post(
          `${baseUrl}/api/changePassword`,
          {
            token,
            username,
            password,
            admin,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        if (response.status === 200) {
          setShowIcon(true);
        }
      } else {
        const response = await axios.post(
          `${baseUrl}/api/signup`,
          {
            username,
            password,
            admin,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );

        if (response.status === 200) {
          setShowIcon(true);
          setUsername("");
          setPassword("");
          setRepeatedPassword("");
          setAdmin(false);
        }
      }
    } catch (error) {
      setShowError(true);
      setError("The user name is already exist.");
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}

      <form onSubmit={handleSignup} className="userForm">
        {edit && (
          <button
            onClick={() => setShowEdit && setShowEdit(false)}
            className="w-25"
          >
            Back to edit
          </button>
        )}
        {edit ? <h2>Edit user</h2> : <h2>Signup</h2>}
        <label>
          Username:
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            readOnly={edit}
          />
        </label>
        <label>
          {edit ? "New password" : "Password:"}

          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </label>
        <label>
          {edit ? "Repeat new password" : "Repeat your Password:"}
          <input
            type="password"
            value={repeatedPassword}
            onChange={(e) => setRepeatedPassword(e.target.value)}
            required
          />
        </label>
        <label className="custom-checkbox">
          Admin
          <input
            type="checkbox"
            checked={admin}
            onChange={(e) => setAdmin(e.target.checked)}
          />
          <span className="checkmark"></span>
        </label>
        <br />
        <button type="submit">{edit ? "Edit user" : "Signup"}</button>
        {showError && <p className="text-danger">{error}</p>}
      </form>
    </>
  );
};

export default SignUp;
