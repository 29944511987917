import React, { useEffect, useState } from "react";
import StoreData from "../data/StoreData.json";
import { useLocation } from "react-router-dom";
import "./ProductDetails.css";
// import StoreProducts from "../components/StoreProducts";
type StorProductPropsType = {
  id: number;
  title: string;
  by: string;
  description: string;
  colors: { color: string; productImg: string; sizes: string[] }[];
  price: string;
};

const ProductDetails = () => {
  const [productData, setProductData] = useState<StorProductPropsType | null>(
    null
  );
  const [addToCart, SetAddToCart] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const location = useLocation();
  const [sizes, setSizes] = useState<string[] | null>(null);

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    StoreData.forEach((d: StorProductPropsType) => {
      if (d.id === Number(id)) {
        setProductData(d);
      }
    });
    productData?.colors.forEach((c) => {
      setSizes(c.sizes);
    });
  }, [productData]);

  return (
    <div className="d-flex productDetailsMainDiv">
      <div className="productDetailsImgDiv d-flex">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/shakomako-5e3f9.appspot.com/o/Store%2Ftshirt-black.png?alt=media&token=2133ef95-e7c6-4c8e-b243-5827c35c43e4"
          alt=""
          style={{ width: "90%" }}
        />
      </div>
      <div className=" productDetailsDiv pt-4 pt-md-5">
        <h1 className="productName">{productData?.title}</h1>
        <p className="designedBy">{productData?.by}</p>
        <p className="designersDetails my-3 my-md-5">
          {productData?.description}
        </p>
        <div className="d-flex justify-content-between">
          <div>
            <p className="ColorAndSize">Color</p>
            <div>
              {productData?.colors.map((c) => {
                return (
                  <div
                    className="colorDiv d-inline-block"
                    style={{ backgroundColor: c.color }}
                    onClick={() => setImageUrl(c.productImg)}
                  ></div>
                );
              })}
            </div>
            <div>
              <h1 className="priceDiv">Kr {productData?.price}</h1>
              <p className="TaxDiv">Tax Included</p>
            </div>
          </div>
          <div>
            <p className="ColorAndSize">Size</p>
            <div className="d-flex">
              {sizes?.map((s) => {
                return (
                  <div className="SizeDiv">
                    <span>{s}</span>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center">
              {!addToCart && (
                <button
                  className="productAddToCart mt-4"
                  onClick={() => SetAddToCart(true)}
                >
                  Add To Cart
                </button>
              )}
              {addToCart && (
                <div className="productCounts d-flex justify-content-around align-items-center mt-4">
                  <button className="productCountsButton">+</button>
                  <p className="m-0">0</p>
                  <button className="productCountsButton">-</button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="mt-5">
          <p className="moreProducts">You might also like:</p>
          {/* <div className="d-flex justify-content-between row">
            {StoreData.slice(0, 3).map((p) => {
              return (
                <StoreProducts
                  id={p.id}
                  by={p.by}
                  colors={p.colors}
                  description={p.description}
                  price={p.price}
                  sizes={p.sizes}
                  title={p.title}
                />
              );
            })}
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
