import React, { useEffect, useRef, useState } from "react";
import HomeEventsCard from "../components/HomeEventsCard";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { baseUrl } from "../DataUrl";

type homeEventPropsType = {
  id: string;
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
};

type ArticlesType = {
  id: string;
  ar: homeEventPropsType;
  en: homeEventPropsType;
  backgroundImg: string;
}[];

const Home = () => {
  const { language } = useChangeLanguage();
  const [articles, setArticlesData] = useState<ArticlesType>();
  const effectRan = useRef(false);

  const getData = async () => {
    await fetch(`${baseUrl}/api/articles`)
      .then((response) => response.json())
      .then((datab) => {
        setArticlesData(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  const mainDivCss =
    language === "ar"
      ? "d-flex flex-wrap flex-row-reverse justify-content-start"
      : "d-flex flex-wrap";
  return (
    <div className="flex justify-content-between w-100">
      <div className={mainDivCss}>
        {language === "ar" && articles !== undefined
          ? articles.map((art) => {
              return (
                <HomeEventsCard
                  key={art.id}
                  id={art.id}
                  backgroundImg={art.backgroundImg}
                  date={art.ar.date}
                  place={art.ar.place}
                  subTitle={art.ar.subTitle}
                  title={art.ar.title}
                  type={art.ar.type}
                />
              );
            })
          : articles?.map((art) => {
              return (
                <HomeEventsCard
                  key={art.id}
                  id={art.id}
                  backgroundImg={art.backgroundImg}
                  date={art.en.date}
                  place={art.en.place}
                  subTitle={art.en.subTitle}
                  title={art.en.title}
                  type={art.en.type}
                />
              );
            })}
      </div>
    </div>
  );
};

export default Home;
