import React, { createContext, useContext, useState } from "react";

type storeProductsType = {
  chosenProducts: product[];
  cartQuantity: number;
  addProductsToCart: (product: product) => void;
  getItemQuantity: (id: number) => number;
  decreaseCartQuantity: (product: product) => void;
};

type product = {
  id?: number;
  productId?: number;
  size?: string;
  color?: string;
  price?: string;
  quantity: number;
};

type StoreProductsContextProviderProps = {
  children: React.ReactNode;
};

const StoreProductsContext = createContext({} as storeProductsType);

export function useStoreProduct() {
  return useContext(StoreProductsContext);
}

export const StoreProductsContextProvider = ({
  children,
}: StoreProductsContextProviderProps) => {
  const [chosenProducts, setChosenProducts] = useState<product[]>([]);

  const getItemQuantity = (id: number) => {
    return chosenProducts?.find((item) => item.id === id)?.quantity || 0;
  };

  const addProductsToCart = (product: product) => {
    setChosenProducts((currentProducts) => {
      if (currentProducts.find((item) => item.id === product.id) == null) {
        return [...currentProducts, { ...product, quantity: 1 }];
      } else {
        return currentProducts?.map((item) => {
          if (item.id === product.id) {
            return { ...item, quantity: item.quantity + 1 };
          } else {
            return item;
          }
        });
      }
    });
  };

  const decreaseCartQuantity = (product: product) => {
    setChosenProducts((currItems) => {
      if (currItems?.find((item) => item.id === product.id)?.quantity === 1) {
        return currItems.filter((item) => item.id !== product.id);
      } else {
        return currItems?.map((item) => {
          if (item.id === product.id) {
            return { ...item, quantity: item.quantity - 1 };
          } else {
            return item;
          }
        });
      }
    });
  };

  const cartQuantity = chosenProducts.reduce(
    (quantity, item) => item.quantity + quantity,
    0
  );

  return (
    <StoreProductsContext.Provider
      value={{
        addProductsToCart,
        chosenProducts,
        getItemQuantity,
        decreaseCartQuantity,
        cartQuantity,
      }}
    >
      {children}
    </StoreProductsContext.Provider>
  );
};
