import React, { useEffect, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import "./EventCard.css";
import { useNavigate } from "react-router-dom";
import { useChangeLanguage } from "../context/ChangeLanguageContext";

type eventPropsType = {
  id: string;
  backgroundImg: string;
  date: string;
  type: string;
  place: string;
};

const EventsCard = ({
  id,
  backgroundImg,
  date,
  place,
  type,
}: eventPropsType) => {
  const navigate = useNavigate();
  const { language } = useChangeLanguage();
  const [addBackground, setBackground] = useState("inherit");
  const [isHover, setIsHover] = useState(false);
  const containerRef = useRef<HTMLImageElement | null>(null);

  // State to store dimensions
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    // Function to update dimensions
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = width * 0.52; // Calculate height as 52% of the width
        setDimensions({ width, height });
      }
    };

    updateDimensions(); // Update dimensions on initial render
    window.addEventListener("resize", updateDimensions); // Update dimensions on window resize

    return () => window.removeEventListener("resize", updateDimensions); // Cleanup the event listener
  }, []);

  const MainDivStyle =
    language === "en"
      ? "d-flex justify-content-between pt-4"
      : "d-flex justify-content-between flex-row-reverse pt-4";

  const handleMouseEnter = () => {
    setIsHover(true);
    const colors = ["#C0D1BF", "#B5B9C4", "#EFEAD1", "#FFDD64", "#FF0000"];

    const randomNumber = Math.floor(Math.random() * 8);
    setBackground(colors[randomNumber]);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
    setBackground("inherit");
  };

  return (
    <div className="mb-5">
      <Card
        className="maiCard"
        onClick={() => navigate("/eventDetails?id=" + id)}
        style={{
          backgroundColor: "inherit",
          border: "none",
          borderRadius: 0,
          cursor: "pointer",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Card.Img
          className="eventCardImg"
          variant="top"
          src={backgroundImg}
          ref={containerRef}
          style={{
            width: "100%", // or any other width you prefer
            height: `${dimensions.height}px`, // Set the height based on calculated dimensions
            overflow: "hidden",
            position: "relative",
          }}
        />
        <Card.Body
          className="p-2"
          style={{
            backgroundColor: addBackground,
          }}
        >
          <Card.Text
            className={MainDivStyle}
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            <div className="d-flex flex-column">
              <span
                className="EventMainFooter"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {language === "en" ? "date:" : ":التاريخ"}
              </span>
              <span
                className="EventSubFooter"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {date}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span
                className="EventMainFooter"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {language === "en" ? "type:" : ":التصنيف"}
              </span>
              <span
                className="EventSubFooter"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {type.toUpperCase()}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span
                className="EventMainFooter"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {" "}
                {language === "en" ? "place:" : ":المكان"}
              </span>
              <span className="EventSubFooter">{place}</span>
            </div>
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default EventsCard;
