import React from "react";

import "./checkout.css";
const Checkout = () => {
  const onChangeValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  return (
    <div className="d-flex row w-100">
      <div className="col-md-9"> Coming soon......</div>
      {/* <div className="col-md-3">
        <div className="shoppingDetails">
          <div className="d-flex justify-content-between align-items-center p-1">
            <p className="shoppingDetailsTitles">Sub Total</p>
            <p className="shoppingDetailsPrice">Kr 1000.00</p>
          </div>
          <div className="d-flex  justify-content-between align-items-center p-1">
            <p className="shoppingDetailsTitles">Shipping</p>
            <p className="shoppingDetailsPrice">kr 50.00</p>
          </div>
          <div className="d-flex  justify-content-between align-items-center p-1">
            <p className="shoppingDetailsTitles">Bag Total</p>
            <p className="shoppingDetailsToPay">kr 1050.00</p>
          </div>
          <div className="p-1">
            <p>Payment method</p>
            <div onChange={onChangeValue} className="d-flex w-100 flex-column">
              <div className="w-100 row mb-1">
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    value="Paypal"
                    name="payMethod"
                    className="radioInput"
                  />
                  <span className="radioInputText"> Paypal</span>
                </div>
                <div className="col-md-7 d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    value="DebitCredit"
                    name="payMethod"
                    className="radioInput"
                  />
                  <span className="radioInputText"> Debit / Credit</span>
                </div>
              </div>
              <div className="w-100 row">
                <div className="col-md-7 d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    value="BankTransfer"
                    name="payMethod"
                    className="radioInput"
                  />
                  <span className="radioInputText"> Bank Transfer</span>
                </div>
                <div className="col-md-5 d-flex justify-content-start align-items-center">
                  <input
                    type="radio"
                    value="Swish"
                    name="payMethod"
                    className="radioInput"
                  />
                  <span className="radioInputText"> Swish</span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="ProceedToCheckoutTitle mt-4 mb-2 p-1">
              Delivery Address
            </p>
            <div className="p-3 addressDiv">
              <p className="addressP m-0">Selina K,</p>
              <p className="addressP m-0">21/3, Ragava Street</p>
              <p className="addressP m-0">Silver tone</p>
              <p className="addressP m-0">Kodaikanal - 655 789</p>
            </div>
            <div className="my-2 p-1">
              <img src="./imges/addNewAdress.svg" />
              <span className="addressP mx-2">Add a New Address</span>
            </div>
          </div>
          <div className="d-flex py-4 p-1">
            <img
              src="./imges/deliveryShipping.svg"
              style={{ width: "45px", height: "45px" }}
              alt="car"
            />
            <div className="d-flex flex-column" style={{ marginLeft: "rem" }}>
              <span className="EstimatedDelivery">Estimated delivery: </span>
              <span className="EstimatedDeliveryDate">25 March 2024</span>
            </div>
          </div>
        </div>
        <button className="ProceedToPay mt-2">Pay and Complete Order</button>
        <div className="errorDiv mt-2 text-center">
          An error has occurred. Please try again.
        </div>
      </div> */}
    </div>
  );
};

export default Checkout;
