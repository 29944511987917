import React, { useEffect, useState } from "react";
import "./EditOrDeleteTable.css";
// import AddEvent from "./AddEvent";
import axios from "axios";
import SignUp from "./SignUp";
import { baseUrl } from "../DataUrl";

// type homeEventPropsType = {
//   id: string;
// };

type ArticlesType = {
  id: string;
}[];

const UserEditOrDeleteTable: React.FC = () => {
  const [users, setUsersData] = useState<ArticlesType>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEdit, setShowEdit] = useState(false);
  const [userId, setUserId] = useState<string | null>(null);

  const rowsPerPage = 10;

  const totalPages = Math.ceil(users?.length / rowsPerPage);

  const handleClick = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const getData = async () => {
    await fetch(`${baseUrl}/api/users`)
      .then((response) => response.json())
      .then((dataB) => {
        setUsersData(dataB);
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteEvent = async (userId: string) => {
    const token = localStorage.getItem("authToken");
    const confirmed = window.confirm(
      "Are you sure you want to delete this document?"
    );

    if (!confirmed) {
      // If the user cancels, do nothing
      return;
    }

    try {
      const response = await axios.delete(
        `${baseUrl}/api/deleteUsers/${userId}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      alert(response.data.message);
      if (response.status === 200) {
        getData();
      }
    } catch (error) {
      console.error("Error deleting document or images:", error);
      alert("Failed to delete document or images");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (showEdit) {
    return <SignUp edit={true} userId={userId} setShowEdit={setShowEdit} />;
  } else {
    return (
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {users?.length > 0 &&
              users?.map((row) => (
                <tr key={row.id}>
                  <td>{row.id}</td>
                  <td>
                    <button
                      className="edit"
                      onClick={() => {
                        setShowEdit(true);
                        setUserId(row.id);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDeleteEvent(row.id)}
                      className="delete"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            onClick={() => handleClick(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handleClick(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handleClick(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    );
  }
};

export default UserEditOrDeleteTable;
