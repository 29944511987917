import { useState } from "react";
import "./ArchiveDetails.css";
import { useChangeLanguage } from "../context/ChangeLanguageContext";

type archivePropsType = {
  title: string;
  subTitle: string;
  imagesUrl: string[];
};

const ArchiveDetails = ({ imagesUrl, subTitle, title }: archivePropsType) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { language } = useChangeLanguage();
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const handleNext = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) =>
        prevIndex !== null ? (prevIndex + 1) % imagesUrl.length : null
      );
    }
  };

  const handlePrev = () => {
    if (currentIndex !== null) {
      setCurrentIndex((prevIndex) =>
        prevIndex !== null
          ? (prevIndex - 1 + imagesUrl.length) % imagesUrl.length
          : null
      );
    }
  };

  return (
    <>
      {currentIndex ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img
            src="/arrow-left.svg"
            onClick={handlePrev}
            style={{
              position: "absolute",
              left: "2px",
              top: "50%",
              cursor: "pointer",
              zIndex: 101,
            }}
            alt="arrow-left"
          />
          <img
            src="/arrow-right.svg"
            onClick={handleNext}
            style={{
              position: "absolute",
              right: "2px",
              top: "50%",
              cursor: "pointer",
              zIndex: 101,
            }}
            alt="arrow-right"
          />
          <div
            onClick={() => setCurrentIndex(null)}
            className="imageViewer"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,0.8)",
              zIndex: 100,
            }}
          >
            <img
              src="/x-mark-8.svg"
              onClick={() => setCurrentIndex(null)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              alt="x mark"
            />

            <img
              src={imagesUrl[currentIndex - 1]}
              alt="test"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "85%",
                maxHeight: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </div>
        </div>
      ) : null}
      <div className="ArchiveMaiDiv">
        <h1
          className="EventDetailsHeader"
          style={{
            textAlign: language === "ar" ? "right" : "left",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          {title.toLocaleUpperCase()}
        </h1>
        <p
          className="EventDetailsSubHeader"
          style={{
            textAlign: language === "ar" ? "right" : "left",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          {subTitle}
        </p>
        <div
          className="d-flex overflow-scroll mt-3 mt-lg-5 align-items-center scrollableDiv"
          style={{ flexDirection: language === "ar" ? "row-reverse" : "row" }}
        >
          {imagesUrl.map((image, i) => {
            return (
              <img
                key={i}
                src={image}
                className="EventDetailsImg"
                onClick={() => {
                  setCurrentIndex(i + 1);
                }}
                alt={title}
                style={{
                  marginLeft: language === "ar" ? "4rem" : "0",
                  marginRight: language === "ar" ? "0" : "4rem",
                }}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ArchiveDetails;
