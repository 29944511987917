import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import axios from "axios";
import "./AddArticle.css";
import VerificationIcon from "./VerificationIcon";
import { baseUrl } from "../DataUrl";

const EditPartners: React.FC = () => {
  const [showIcon, setShowIcon] = useState(false);
  const [formData, setFormData] = useState<{
    en: string;
    ar: string;
  }>({
    en: "",
    ar: "",
  });

  const getData = async () => {
    await fetch(`${baseUrl}/api/partners`)
      .then((response) => response.json())
      .then((datab) => {
        setFormData(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (showIcon) {
      const timer = setTimeout(() => {
        setShowIcon(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showIcon]);

  const handleChange = (
    lang: "en" | "ar",
    e: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [lang]: value,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    const token = localStorage.getItem("authToken");

    try {
      const response = await axios.post(`${baseUrl}/api/partners`, formData, {
        headers: {
          "x-access-token": token,
        },
      });
      console.log("Response:", response.data);
      if (response.status === 200) {
        setShowIcon(true);
      }
    } catch (error) {
      console.error("Error uploading partners data:", error);
    }
  };

  return (
    <>
      {showIcon && <VerificationIcon />}
      <form onSubmit={handleSubmit}>
        <h2>Add Partners Details</h2>

        <Title title="Edit Partners in English" />
        {renderPartnersForm("en")}

        <Title title="تعديل نص الشركاء" rtl />
        {renderPartnersForm("ar")}

        <button type="submit">Submit</button>
      </form>
    </>
  );

  function Title({ title, rtl }: { title: string; rtl?: boolean }) {
    return <h2 className={rtl ? "rtl" : ""}>{title}</h2>;
  }

  function renderPartnersForm(lang: "en" | "ar") {
    return (
      <div className={lang === "ar" ? "rtl" : ""}>
        <label>
          {lang === "ar" ? "الوصف" : "Description"}:
          <textarea
            name="description"
            value={formData[lang]}
            onChange={(e) => handleChange(lang, e)}
            required
          />
        </label>
      </div>
    );
  }
};

export default EditPartners;
