import StoreProducts from "../components/StoreProducts";
import "./store.css";
import StoreData from "../data/StoreData.json";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { DisplayTextWithNewLines } from "../utailities/FIxTheText";
import { useEffect, useState } from "react";
import { baseUrl } from "../DataUrl";

type storeDataType = {
  firstParagraph: string;
  secondParagraph: string;
};
const Store = () => {
  const { language } = useChangeLanguage();
  const [storeParagraphsData, setStoreParagraphsData] =
    useState<storeDataType | null>(null);

  const getData = async () => {
    await fetch(`${baseUrl}/api/store`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setStoreParagraphsData(datab.ar)
          : setStoreParagraphsData(datab.en);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <div>
      <div className="d-flex justify-content-between row">
        {StoreData.slice(0, 3).map((p) => {
          return (
            <StoreProducts
              id={p.id}
              by={p.by}
              colors={p.colors}
              description={p.description}
              price={p.price}
              title={p.title}
            />
          );
        })}
      </div>
      <p
        className="storeP"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        {storeParagraphsData !== null &&
          (language === "ar" ? (
            <DisplayTextWithNewLines
              text={storeParagraphsData.firstParagraph}
            />
          ) : (
            <DisplayTextWithNewLines
              text={storeParagraphsData.firstParagraph}
            />
          ))}
      </p>
      <div className="d-flex row">
        {StoreData.slice(0, 6).map((p) => {
          return (
            <StoreProducts
              id={p.id}
              by={p.by}
              colors={p.colors}
              description={p.description}
              price={p.price}
              title={p.title}
            />
          );
        })}
      </div>
      <p
        className="storeP"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        {storeParagraphsData !== null &&
          (language === "ar" ? (
            <DisplayTextWithNewLines
              text={storeParagraphsData.secondParagraph}
            />
          ) : (
            <DisplayTextWithNewLines
              text={storeParagraphsData.secondParagraph}
            />
          ))}
      </p>
      <div className="d-flex row">
        {StoreData.slice(0, 6).map((p) => {
          return (
            <StoreProducts
              id={p.id}
              by={p.by}
              colors={p.colors}
              description={p.description}
              price={p.price}
              title={p.title}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Store;
