import React, { useEffect, useState } from "react";
import "./Article.css";
// import HomeEventsCard from "../components/HomeEventsCard";
// import ArticleData from "../data/articleData.json";
import { useLocation } from "react-router-dom";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { DisplayTextWithNewLines } from "../utailities/FIxTheText";
import { baseUrl } from "../DataUrl";

type ArticleDataType = {
  id: number;
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
  description: string;
  imagesUrl: string[];
  takingBy: string;
  paragraphs: { paragraphTitle: string; paragraphDetails: string }[];
};

const Article = () => {
  const [articleData, setArticleData] = useState<ArticleDataType | null>(null);
  const location = useLocation();
  const { language } = useChangeLanguage();
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number | null>(null);

  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/articles/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setArticleData({ ...datab.ar, imagesUrl: datab.imagesUrl })
          : setArticleData({ ...datab.en, imagesUrl: datab.imagesUrl });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    id !== null && getData(id);
  }, [language]);

  const flexClassToReverse =
    language === "ar" ? "d-flex cardBody flex-row-reverse" : "d-flex cardBody";

  const handleNext = () => {
    if (currentIndex !== null && articleData?.imagesUrl) {
      setCurrentIndex((prevIndex) =>
        prevIndex !== null
          ? (prevIndex + 1) % articleData?.imagesUrl.length
          : null
      );
    }
  };

  const handlePrev = () => {
    if (currentIndex !== null && articleData?.imagesUrl) {
      setCurrentIndex((prevIndex) =>
        prevIndex !== null
          ? (prevIndex - 1 + articleData?.imagesUrl.length) %
            articleData?.imagesUrl.length
          : null
      );
    }
  };

  return (
    <>
      {" "}
      {currentIndex ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            zIndex: 1000,
          }}
        >
          <img
            src="/arrow-left.svg"
            onClick={handlePrev}
            style={{
              position: "absolute",
              left: "2px",
              top: "50%",
              cursor: "pointer",
              zIndex: 101,
            }}
            alt="arrow-left"
          />
          <img
            src="/arrow-right.svg"
            onClick={handleNext}
            style={{
              position: "absolute",
              right: "2px",
              top: "50%",
              cursor: "pointer",
              zIndex: 101,
            }}
            alt="arrow-right"
          />
          <div
            onClick={() => setCurrentIndex(null)}
            className="imageViewer"
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              background: "rgba(0,0,0,0.8)",
              zIndex: 100,
            }}
          >
            <img
              src="/x-mark-8.svg"
              onClick={() => setCurrentIndex(null)}
              style={{
                position: "absolute",
                top: "5px",
                right: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              alt="x mark"
            />

            <img
              src={articleData?.imagesUrl[currentIndex - 1]}
              alt="test"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "85%",
                maxHeight: "100%",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </div>
        </div>
      ) : null}
      <div>
        <div className="articleMainDiv">
          <div>
            <p
              className="articleTitle"
              style={{
                textAlign: language === "ar" ? "right" : "left",
              }}
            >
              {articleData?.title.toLocaleUpperCase()}
            </p>
            <p
              className="articleUnderTitle"
              style={{
                textAlign: language === "ar" ? "right" : "left",
              }}
            >
              {articleData?.subTitle}
            </p>
          </div>
          <div
            className={flexClassToReverse}
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            <div className="d-flex flex-column">
              <span
                className="mainDetails"
                style={{
                  textAlign: language === "ar" ? "right" : "left",
                }}
              >
                {language === "en" ? "date:" : ":التاريخ"}
              </span>
              <span
                className="subDetails"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {articleData?.date}
              </span>
            </div>
            <div
              className="d-flex flex-column mx-5"
              style={{ textAlign: language === "ar" ? "right" : "left" }}
            >
              <span
                className="mainDetails"
                style={{
                  textAlign: language === "ar" ? "right" : "left",
                }}
              >
                {language === "en" ? "type:" : ":التصنيف"}
              </span>
              <span
                className="subDetails"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {articleData?.type}
              </span>
            </div>
            <div className="d-flex flex-column">
              <span
                className="mainDetails"
                style={{ textAlign: language === "ar" ? "right" : "left" }}
              >
                {language === "en" ? "Place:" : ":المكان"}
              </span>
              <span className="subDetails">{articleData?.place}</span>
            </div>
          </div>
        </div>
        <div className="mb-m-5">
          <div
            className="subText"
            style={{
              marginBottom: "5rem",
              textAlign: language === "ar" ? "right" : "left",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            {/* {articleData?.description} */}
            {articleData?.description && (
              <DisplayTextWithNewLines text={articleData?.description} />
            )}
          </div>
          <div
            className="ArticleImgDiv d-flex"
            style={{ flexDirection: language === "ar" ? "row-reverse" : "row" }}
          >
            {articleData?.imagesUrl &&
              articleData?.imagesUrl.length > 0 &&
              articleData?.imagesUrl.map((img, i) => {
                return (
                  <img
                    key={i + 1}
                    src={img}
                    className="imgStyling"
                    alt="party"
                    onClick={() => setCurrentIndex(i + 1)}
                    style={{
                      marginLeft: language === "ar" ? "4rem" : "0",
                      marginRight: language === "ar" ? "0" : "4rem",
                    }}
                  />
                );
              })}
          </div>
          {articleData?.takingBy && (
            <p className="photoBy">Photos by {articleData?.takingBy}</p>
          )}
        </div>
        <div className="mb-5">
          {articleData?.paragraphs[0].paragraphTitle.trim().length !== 0 &&
            articleData?.paragraphs.map((par, i) => {
              return (
                <div className="my-5" key={i + 1}>
                  <p
                    className="textHeader"
                    style={{
                      textAlign: language === "ar" ? "right" : "left",
                      direction: language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    {par.paragraphTitle}:
                  </p>
                  <div
                    className="subText"
                    style={{
                      textAlign: language === "ar" ? "right" : "left",
                      direction: language === "ar" ? "rtl" : "ltr",
                    }}
                  >
                    {par.paragraphDetails && (
                      <DisplayTextWithNewLines text={par.paragraphDetails} />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
        <div style={{ borderTop: "1px solid #1c1c1c" }} className="pt-5">
          <p className="textHeader">Related Topics:</p>
        </div>
        {/* <HomeEventsCard /> */}
      </div>
    </>
  );
};

export default Article;
