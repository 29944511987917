// AuthContext.tsx
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";
import axios from "axios";
import { baseUrl } from "../DataUrl";

interface AuthContextType {
  isAuthenticated: boolean;
  login: (token: string, admin: boolean) => void;
  logout: () => void;
  checkAuth: () => void;
  isAdmin: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const pathName = window.location.pathname;

  const login = (token: string, admin: boolean) => {
    localStorage.setItem("authToken", token);
    setIsAuthenticated(true);
    setIsAdmin(admin);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setIsAuthenticated(false);
  };

  const checkAuth = async () => {
    const token = localStorage.getItem("authToken");
    if (token) {
      try {
        // Validate token with backend
        const response = await axios.get(`${baseUrl}/api/validateToken`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.status === 200) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error("Token validation failed:", error);
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (pathName === "/login" || pathName === "/dashboard") {
      checkAuth();
      console.log(isAuthenticated);
    }
  }, [isAuthenticated, pathName]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, login, logout, checkAuth, isAdmin }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
