import React from "react";
import "./Login.css";

const VerificationIcon = () => {
  return (
    <div className="verificationDiv">
      <img src="/verification.png" alt="verification" />
    </div>
  );
};

export default VerificationIcon;
