import EventsCard from "../components/EventsCard";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { useState, useEffect } from "react";
import { baseUrl } from "../DataUrl";

type EventPropsType = {
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
  buyTicketUrl: string;
  description: string;
};

type EventsType = {
  id: string;
  ar: EventPropsType;
  en: EventPropsType;
  backgroundImg: string;
}[];

const Events = () => {
  const { language } = useChangeLanguage();
  const [events, setEventsData] = useState<EventsType>();

  const getData = async () => {
    await fetch(`${baseUrl}/api/events`)
      .then((response) => response.json())
      .then((datab) => {
        setEventsData(datab);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {language === "ar"
        ? events?.map((e) => {
            return (
              <EventsCard
                key={e.id}
                backgroundImg={e.backgroundImg}
                date={e.ar.date}
                id={e.id}
                place={e.ar.place}
                type={e.ar.type}
              />
            );
          })
        : events?.map((e) => {
            return (
              <EventsCard
                key={e.id}
                backgroundImg={e.backgroundImg}
                date={e.en.date}
                id={e.id}
                place={e.en.place}
                type={e.en.type}
              />
            );
          })}
    </div>
  );
};

export default Events;
