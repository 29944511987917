import React, { createContext, useContext, useState } from "react";

type BackgroundColorContextType = {
  ChangeColor: (color: string) => void;
  backgroundColor: string;
};

type BackgroundColorProviderProps = {
  children: React.ReactNode;
};

const backgroundColorContext = createContext({} as BackgroundColorContextType);

export function useChangeBackGroundColor() {
  return useContext(backgroundColorContext);
}

export const ChangeBackGroundColorContextProvider = ({
  children,
}: BackgroundColorProviderProps) => {
  const [backgroundColor, setBackgroundColor] = useState<string>("#f3f3f3");

  const ChangeColor = (color: string = "#f3f3f3") => {
    setBackgroundColor(color);
    document.body.style.backgroundColor = color;
  };

  return (
    <backgroundColorContext.Provider value={{ ChangeColor, backgroundColor }}>
      {children}
    </backgroundColorContext.Provider>
  );
};
