import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { useChangeLanguage } from "../context/ChangeLanguageContext";
import { DisplayTextWithNewLines } from "../utailities/FIxTheText";
import { baseUrl } from "../DataUrl";

type EventDataType = {
  id: number;
  backgroundImg: string;
  title: string;
  subTitle: string;
  date: string;
  type: string;
  place: string;
  description: string;
  buyTicketUrl: string;
  paragraphs: { paragraphTitle: string; paragraphDetails: string }[];
};

const EventDetails = () => {
  const [eventData, setEventData] = useState<EventDataType | null>(null);
  const location = useLocation();
  const { language } = useChangeLanguage();
  const containerRef = useRef<HTMLImageElement | null>(null);

  // State to store dimensions
  const [dimensions, setDimensions] = useState<{
    width: number;
    height: number;
  }>({ width: 0, height: 0 });

  useEffect(() => {
    // Function to update dimensions
    const updateDimensions = () => {
      if (containerRef.current) {
        const width = containerRef.current.offsetWidth;
        const height = width * 0.52; // Calculate height as 52% of the width
        setDimensions({ width, height });
      }
    };

    updateDimensions(); // Update dimensions on initial render
    window.addEventListener("resize", updateDimensions); // Update dimensions on window resize

    return () => window.removeEventListener("resize", updateDimensions); // Cleanup the event listener
  }, []);
  const getData = async (id: string) => {
    await fetch(`${baseUrl}/api/events/${id}`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setEventData({ ...datab.ar, backgroundImg: datab.backgroundImg })
          : setEventData({ ...datab.en, backgroundImg: datab.backgroundImg });
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const id = new URLSearchParams(location.search).get("id");
    getData(id!);
    console.log(eventData);
  }, [language]);

  const MainDivStyle =
    language === "en"
      ? "d-flex justify-content-between pt-4 w-100"
      : "d-flex justify-content-between pt-4 w-100 flex-row-reverse ";

  const ButtonStyle =
    language === "en" ? "d-flex" : "d-flex justify-content-end";

  return (
    <div>
      <h1
        className="EventDetailsTitle"
        style={{ textAlign: language === "ar" ? "right" : "left" }}
      >
        {eventData?.title.toLocaleUpperCase()}
      </h1>
      <p
        className="EventDetailsSubTitle mb-5"
        style={{ textAlign: language === "ar" ? "right" : "left" }}
      >
        {eventData?.subTitle}
      </p>
      {/* <div
        style={{
          backgroundImage: `url(${eventData?.backgroundImg})`,
          backgroundSize: "cover",
        }}
        className="EventImgStyling"
      ></div> */}
      <img
        src={eventData?.backgroundImg}
        className="EventImgStyling"
        alt=""
        ref={containerRef}
        style={{
          width: "100%", // or any other width you prefer
          height: `${dimensions.height}px`, // Set the height based on calculated dimensions
          overflow: "hidden",
          position: "relative",
        }}
      />

      <div className={MainDivStyle}>
        <div className="d-flex flex-column">
          <span
            className="EventMainFooter"
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            {language === "en" ? "date:" : ":التاريخ"}
          </span>
          <span
            className="EventSubFooter"
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            {eventData?.date}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span
            className="EventMainFooter"
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            {language === "en" ? "type:" : ":التصنيف"}
          </span>
          <span
            className="EventSubFooter"
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            {eventData?.type}
          </span>
        </div>
        <div className="d-flex flex-column">
          <span
            className="EventMainFooter"
            style={{ textAlign: language === "ar" ? "right" : "left" }}
          >
            {language === "en" ? "Place:" : ":المكان"}
          </span>
          <span className="EventSubFooter">{eventData?.place}</span>
        </div>
      </div>
      <div>
        <div className={ButtonStyle}>
          <a
            href={eventData?.buyTicketUrl}
            target="_blank"
            rel="noreferrer"
            className="eventDetailsButton"
          >
            {language === "en" ? "Buy Tickets" : "اشتري التذاكر"}
          </a>
        </div>
        <div
          className="eventDetailsP"
          style={{
            textAlign: language === "ar" ? "right" : "left",
            direction: language === "ar" ? "rtl" : "ltr",
          }}
        >
          {/* {eventData?.description} */}
          {eventData?.description && (
            <DisplayTextWithNewLines text={eventData?.description} />
          )}
        </div>
        {eventData?.paragraphs[0].paragraphTitle.trim().length !== 0 && (
          <h1
            className="eventDetailsH1 mb-5"
            style={{
              textAlign: language === "ar" ? "right" : "left",
              direction: language === "ar" ? "rtl" : "ltr",
            }}
          >
            {language === "en" ? "Lineup:" : "اصطفوا:"}
          </h1>
        )}
        {eventData?.paragraphs[0].paragraphTitle.trim().length !== 0 &&
          eventData?.paragraphs.map((e, i) => {
            return (
              <div key={i}>
                <h1
                  className="eventDetailsH1 "
                  style={{
                    textAlign: language === "ar" ? "right" : "left",
                    direction: language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  {e.paragraphTitle.toLocaleUpperCase()}
                </h1>
                <div
                  className="eventDetailsP mb-5"
                  style={{
                    textAlign: language === "ar" ? "right" : "left",
                    direction: language === "ar" ? "rtl" : "ltr",
                  }}
                >
                  {e.paragraphDetails && (
                    <DisplayTextWithNewLines text={e.paragraphDetails} />
                  )}
                </div>
              </div>
            );
          })}
        <div className={ButtonStyle}>
          <a
            href={eventData?.buyTicketUrl}
            target="_blank"
            rel="noreferrer"
            className="eventDetailsButton"
          >
            {language === "en" ? "Buy Tickets" : "اشتري التذاكر"}
          </a>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
