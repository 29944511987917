import React, { createContext, useContext, useState } from "react";

type LanguageContextType = {
  changeLanguage: (language: "ar" | "en") => void;
  language: "ar" | "en";
};

type LanguageContextProviderProps = {
  children: React.ReactNode;
};

const LanguageContext = createContext({} as LanguageContextType);

export function useChangeLanguage() {
  return useContext(LanguageContext);
}

export const ChangeLanguageContextProvider = ({
  children,
}: LanguageContextProviderProps) => {
  const [language, setLanguage] = useState<"ar" | "en">("en");

  const changeLanguage = (language: "ar" | "en" = "en") => {
    console.log(language);
    setLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ changeLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  );
};
