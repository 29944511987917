import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";
import { Routes, Route } from "react-router-dom";
import Events from "./pages/Events";
import Store from "./pages/Store";
import Archive from "./pages/Archive";
import Partners from "./pages/Partners";
import SideNavbar from "./components/SideNavbar";
import { useChangeBackGroundColor } from "./context/ChangeBackGroundColorContext";
import Home from "./pages/Home";
import Article from "./pages/Article";
import EventDetails from "./pages/EventDetails";
import { useEffect } from "react";
import ArabicNavbar from "./components/ArabicNavbar";
import { useChangeLanguage } from "./context/ChangeLanguageContext";
import ProductDetails from "./pages/ProductDetails";
import styled from "styled-components";
import PhoneNavbar from "./components/PhoneNavbar";
import Checkout from "./pages/Checkout";
import Login from "./dashboard/Login";
import Dashboard from "./dashboard/Home";
import PrivateRoute from "./PrivateRoute";
import AboutUs from "./pages/AboutUs";

function App() {
  const { ChangeColor, backgroundColor } = useChangeBackGroundColor();
  const { changeLanguage, language } = useChangeLanguage();

  useEffect(() => {
    console.log(window.location.pathname);
    const pathName = window.location.pathname;
    if (pathName === "/" || pathName === "/article") {
      ChangeColor("#F3F3F3");
    } else if (pathName === "/events" || pathName === "/eventDetails") {
      ChangeColor("#FFDBD8");
    } else if (pathName === "/store" || pathName === "/checkout") {
      ChangeColor("#EFEAD1");
    } else if (pathName === "/archive") {
      ChangeColor("#C0D1BF");
    } else if (pathName === "/partners") {
      ChangeColor("#B5B9C4");
    } else if (pathName === "/login") {
      ChangeColor("#B5B9C4");
    } else {
      ChangeColor("#B5B9C4");
    }
  }, []);

  return (
    <div
      className="App"
      style={{
        width: "100vw",
        maxWidth: "100%",
        backgroundColor: backgroundColor,
      }}
    >
      <PhoneNavbar />
      {language === "ar" ? <ArabicNavbar /> : <Navbar />}
      <div
        className={language === "ar" ? "d-flex  flex-row-reverse" : "d-flex"}
        // style={{ width: "100vw" }}
      >
        <SideNavbar />
        <MainDiv className="px-md-5 px-3">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/events" element={<Events />}></Route>
            <Route path="/store" element={<Store />}></Route>
            <Route path="/archive" element={<Archive />}></Route>
            <Route path="/partners" element={<Partners />}></Route>
            <Route path="/aboutus" element={<AboutUs />}></Route>
            <Route path="/article" element={<Article />}></Route>
            <Route path="/eventDetails" element={<EventDetails />}></Route>
            <Route path="/productDetails" element={<ProductDetails />}></Route>
            <Route path="/checkout" element={<Checkout />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
            </Route>
          </Routes>
        </MainDiv>
      </div>
    </div>
  );
}

export default App;

const MainDiv = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: 85%;
  }

  @media (max-width: 820px) {
    padding-top: 5rem;
  }
`;
