import { useChangeLanguage } from "../context/ChangeLanguageContext";
import "./Partners.css";
import { useEffect, useState } from "react";
import { baseUrl } from "../DataUrl";

const DisplayTextWithNewLines: React.FC<{ text: string }> = ({ text }) => {
  const textWithBreaks = text.replace(/\n/g, "<br />");

  return <div dangerouslySetInnerHTML={{ __html: textWithBreaks }} />;
};

const Partners = () => {
  const { language } = useChangeLanguage();
  const [partnersData, setPartnersData] = useState("");

  const getData = async () => {
    await fetch(`${baseUrl}/api/partners`)
      .then((response) => response.json())
      .then((datab) => {
        language === "ar"
          ? setPartnersData(datab.ar)
          : setPartnersData(datab.en);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getData();
  }, [language]);

  return (
    <div>
      <h1
        className="headText"
        style={{ textAlign: language === "ar" ? "right" : "left" }}
      >
        {language === "ar" ? "شركائنا" : "Our Partners"}
      </h1>
      <p
        className="paragraphText"
        style={{
          textAlign: language === "ar" ? "right" : "left",
          direction: language === "ar" ? "rtl" : "ltr",
        }}
      >
        <DisplayTextWithNewLines text={partnersData} />
      </p>
    </div>
  );
};

export default Partners;
